import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { motion } from "framer-motion";

import Section from "../../components/Section/Section";
import TopicBlurb from "../../components/TopicBlurb/TopicBlurb";

import { ReactComponent as Trees } from "../../svg/trees.svg";
import { ReactComponent as Shoob } from "../../svg/blueshoob.svg";

import { ReactComponent as Twitter } from "../../svg/social-logos/twitter.svg";
import { ReactComponent as LinkedIn } from "../../svg/social-logos/linkedin.svg";
import { ReactComponent as Instagram } from "../../svg/social-logos/instagram.svg";
import { ReactComponent as GitHub } from "../../svg/social-logos/github.svg";

import "./Landing.scss";

const variants = {
    initial: {
        scale: 0.95
    },
    open: {
        scale: 1
    },
    exit: {
        scale: 0.8,
        transition: {
            scale:{
                type: "spring",
                stiffness: 200,
                damping: 10,
                restSpeed: 0.3,
                restDelta: 0.2
            }
        }
    }
};

const Landing = ({ name, overview, description }) => {
    const data = useStaticQuery(graphql`
        query {
          file(relativePath: {eq: "blueshoob.png"}) {
            childImageSharp {
              fixed(width: 400, height: 265) {
              	...GatsbyImageSharpFixed_withWebp
              }
            }
          }

          allSocialMediaYaml {
            nodes {
              id
              link
              name
            }
          }
        }
    `);

    return (
        <Section variants={variants} className="landing">
            <div className="landing__main">
                <h1 className="landing__title">{ name.replace(/ /g, "\n") }</h1>
                <ul className="landing__socialMedias">
                    {data.allSocialMediaYaml.nodes.map(socialMedia => (
                        <li key={socialMedia.id}>
                            <motion.a href={socialMedia.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                initial="rest"
                                whileHover="hover"
                                animate="rest"
                                variants={{
                                    hover: {
                                        scale: 1.05
                                    },
                                    rest: {
                                        scale: 1
                                    }
                                }}
                                transition={{
                                    type: 'spring',
                                    // damping: 8,
                                    // stiffness: 100
                                }}>
                                {{
                                    Twitter: <Twitter title="Twitter" />,
                                    Instagram: <Instagram title="Instagram" />,
                                    LinkedIn: <LinkedIn title="LinkedIn" />,
                                    GitHub: <GitHub title="GitHub" />,
                                }[socialMedia.name]}
                            </motion.a>
                        </li>
                    ))}
                </ul>
                <div className="landing__supportingImage">
                    <Trees />
                </div>
            </div>
            <div className="landing__aside">
                <div className="landing__avatar">
                    <Shoob />
                </div>
                { /*<Img fixed={data.file.childImageSharp.fixed}/>*/}
                <TopicBlurb
                    topic="About me"
                    title={overview}
                    action="See my projects"
                    actionLink="/projects">
                    {description}
                </TopicBlurb>
            </div>
        </Section>
    );
};

export default Landing;
