import React from "react";
import cx from "classnames";

import Section from "../../components/Section/Section";

import "./ColumnSection.scss";

const ColumnSection = ({ children, className, ...props }) => (
    <Section className={cx(className, "column-section")} {...props}>
        {children}
    </Section>
);

export const Column = ({
    children,
    className,
    alignWithBlurb,
    hasStripe,
    ...props
}) => (
    <div className={cx(className, "column-section__column", {
        'column-section__column--alignWithBlurb': alignWithBlurb
    })} {...props}>
        {children}
    </div>
);

export default ColumnSection;
