import React from "react";
import ActionLink from "../ActionLink/ActionLink";

import "./TopicBlurb.scss";

const TopicBlurb = ({ topic, title, children, action, actionLink }) =>  (
    <div className="topic-blurb">
        <span className="topic-blurb__title">{ topic }</span>
        <h2 className="topic-blurb__overview">{title}</h2>
        <p className="topic-blurb__desc">{children}</p>
        {action && <ActionLink to={actionLink}>{action}</ActionLink>}
    </div>
);

export default TopicBlurb;
