import React from "react";
import color from "color";
import { motion } from "framer-motion";

import { ReactComponent as Fork } from "../../svg/fork.svg";
import { ReactComponent as Star } from "../../svg/star.svg";

import { formatNumber } from "../../utils/number";

import "./GitHubRepo.scss";

const GitHubRepo = ({ repository }) => (
    <motion.a
        href={repository.url}
        target="_blank"
        rel="noreferrer noopener"
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 0.95 }}
        transition={{ type: 'spring', damping: 20, stiffness: 250 }}
        className="github-repo">
        <div className="github-repo__header">
            <img
                src={repository.owner.avatarUrl}
                alt={`@${repository.owner.login}`}
                className="github-repo__avatar"/>
            <strong className="github-repo__owner">
                {repository.owner.login}
            </strong>
            <span className="github-repo__slash">/</span>
            <strong className="github-repo__name">
                {repository.name}
            </strong>
        </div>
        <div className="github-repo__body">
            <p className="github-repo__description">{repository.description}</p>
        </div>
        <div className="github-repo__stats">
            <span className="github-repo__statistic">
                <Star/>
                <span><strong>{formatNumber(repository.stargazers.totalCount)}</strong> stars</span>
            </span>

            <span className="github-repo__statistic">
                <Fork/>
                <span><strong>{formatNumber(repository.forks.totalCount)}</strong> forks</span>
            </span>

            {repository.languages.nodes.length > 0 && (
                <strong
                    className="github-repo__pill"
                    style={{
                        background: color({
                            h: color(repository.languages.nodes[0].color).hue(),
                            s: 100,
                            l: 85
                        }),
                        color: color({
                            h: color(repository.languages.nodes[0].color).hue(),
                            s: 80,
                            l: 12
                        })
                    }}>
                    {repository.languages.nodes[0].name}
                </strong>
            )}
        </div>
    </motion.a>
);

export default GitHubRepo;
