import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import GitHubRepo from "../components/GitHubRepo/GitHubRepo";
import { SectionColor } from "../components/Section/Section";
import TopicBlurb from "../components/TopicBlurb/TopicBlurb";

import Landing from "../sections/Landing/Landing";
import ColumnSection, { Column } from "../sections/ColumnSection/ColumnSection";

import { ReactComponent as LookingAround } from "../svg/looking-around.svg";

const IndexPage = ({ data }) => (
    <>
        <SEO title="Home" />
        <Landing
            name="Vihan Bhargava"
            overview="Full-stack developer based in the SF Bay Area."
            description={
                `I'm a student in computer science seeking to work in subjects
                from web design to cybersecurity. Whether it's designing a
                homepage or forensic analysis of bootloaders, I'm probably
                doing something with it!`
            }/>
        <ColumnSection color={SectionColor.blackColor}>
            <Column>
                <TopicBlurb
                    topic="Open-Source"
                    title="Top open-source projects">
                    Most of the modern high-level programming world is built
                    around open-source work. Take a look at some of the
                    projects I've worked on.
                </TopicBlurb>
                <LookingAround />
            </Column>
            <Column alignWithBlurb>
                {data.github.user.pinnedItems.nodes.map(repository => (
                    <GitHubRepo
                        key={repository.id}
                        repository={repository}/>
                ))}
            </Column>
        </ColumnSection>
    </>
);

export const query = graphql`
query {
    github {
        user(login: "vihanb") {
            pinnedItems(first: 6) {
                nodes {
                    ... on GitHub_Repository {
                        id
                        name
                        description
                        stargazers {
                            totalCount
                        }
                        languages(first: 1, orderBy: {field: SIZE, direction: DESC}) {
                            nodes {
                                name
                                color
                            }
                        }
                        forks {
                            totalCount
                        }
                        owner {
                            avatarUrl(size: 64)
                            login
                            url
                        }
                        url
                    }
                }
            }
        }
    }
}
`;

export default IndexPage;
